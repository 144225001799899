import { TranslationsSchema } from './types';

export default {
  name: 'statusPoDetails',
  title: 'Status PO Details',
  sanityId: 'statusPoDetails',
  fields: {
    po_number: 'PO Number',
    order_date: 'Order Date',
    status: 'Status',
    status_definitions: 'Status Definitions',

    po_amount: 'PO Amount',
    po_amount_tooltip: 'Total value of the PO.',
    pr_number: 'PR Number',
    supplier: 'Supplier',
    supplier_id: 'Supplier ID',
    requester: 'Requester',
    preparer: 'Preparer',
    company_code: 'Company Code',
    purchasing_org: 'Purchasing Org',

    invoiced_amount: 'Invoiced Amount',
    invoiced_amount_tooltip: 'Total amount invoiced (posted) to SAP from Ariba + IVT.',
    remaining_amount: 'Remaining amount',
    remaining_amount_tooltip: 'Difference between PO Amount and Invoiced Amount.',
    open_amount: 'Open amount',
    open_amount_tooltip:
      'Amount of invoices in progress in IVT, these are documents waiting for processing.',
    to_be_accrued: 'To be accrued',
    to_be_accrued_title:
      'Difference (if positive) between Services Completed Amount / Goods Receipt and Amount Invoiced.',
    view_in_das_tool: 'View it in DAS tool',

    po_items: 'PO Items',
    po_line: 'PO Line',

    po_information: 'PO Information',
    po_type: 'PO Type',
    amount: 'Amount',
    line_status: 'Line Status',
    to_be_accrued_lineitem: 'To be Accrued',
    accrual_method: 'Accrual method',

    account_information: 'Account Information',
    asset_number: 'Asset Number',
    internal_order: 'Internal Order',
    billing_cost_center: 'Billing (Cost Center)',
    gl_account: 'GL Account',
    split_accounting: 'Split Accounting',
    yes: 'Yes',
    view_in_arriba: 'View in Ariba',
    no: 'No',

    dates: 'Dates',
    need_by: 'Need by',
    service_start: 'Service Start',
    service_end: 'Service End',

    bottom_view_in_arriba: 'VIEW IN ARIBA',
  },
} satisfies TranslationsSchema;
