import { TranslationsSchema } from './types';

export default {
  name: 'manualPrDraft',
  title: 'Manual PR',
  sanityId: 'manualPrDraft',
  fields: {
    loading_data: 'loading data...',

    //steps
    request_details: 'Request Details',
    purchase_details: 'Purchase Details',
    attachments: 'Attachments',
    review_and_submit: 'Review & Submit',

    page_title: 'Manual PR creation',
    form_title: 'Create a Manual Purchase Requisition (PR)',
    next: 'Next',
    edit: 'Edit',

    line_item: 'Line Item',

    create_a_manual_purchase_requisition: 'Create a Manual Purchase Requisition (PR)',
    review_the_details_of_your_request: 'Review the details of your request before continuing.',
    enter_the_details_of_the_goods_and_services:
      'Enter the details of the goods and services you would like to purchase.',
    attach_any_supporting_documents:
      'Attach up to {NUM} supporting documents to your purchase request. Max. file size is {SIZE} per file.',
    review_the_details_of_your_manual: 'Review the details of your manual PR and submit.',

    // step 1 - details
    user_properties: 'User Properties',
    requester: 'Requester',
    preparer: 'Preparer',
    company_code: 'Company Code',
    purchasing_org: 'Purchasing Org',
    purchasing_unit: 'Purchasing Unit',
    supplier_details: 'Supplier Details',
    supplier: 'Supplier',
    ers_self_billing: 'ERS (Self Billing)',

    need_to_make_changes: 'Need to make changes to your request details?',
    access_the_user_properties_modal:
      'Access the user properties modal on the BuyEasy home page to change your request details. Changes made to your user properties will affect the manual PR creation process.',
    go_to_buyeasy: 'Go to buyeasy',

    // step 2 - purchase

    purchase_type_good_label: 'Good',
    purchase_type_good_description: 'A short description.',
    purchase_type_service_label: 'Service',
    purchase_type_service_description: 'A short description.',

    good: 'good',
    service: 'service',
    not_saved_yet: 'Not saved yet',

    please_fix_errors: 'Please fix the following errors:',
    form_header: 'Line Item',
    read_only_message: 'This is a read-only field.',

    purchase_type_field: 'Purchase Type',
    product_name_field: 'Product Name',
    service_name_field: 'Service Name',
    product_name_placeholder: 'Enter a name for your product...',
    service_name_placeholder: 'Enter a name for your service...',
    category_field: 'Category',

    unit_price_field: 'Unit Price',
    service_price_field: 'Price',
    line_item_total_too_big: 'Line item total must be less than {max}',

    service_start_date_field: 'Service Start Date',
    service_end_date_field: 'Service End Date',
    quantity_field: 'Quantity',
    subtotal_field: 'Subtotal',
    unit_of_measure_field: 'Unit of Measure',
    need_by_field: 'Need By',
    start_end_date_message: 'Please select end date that is not earlier than start date',
    need_by_message: 'Need by date is in the past. Please select a valid date.',

    ship_to_field: 'Ship to',
    deliver_to_field: 'Deliver To',
    bill_to_field: 'Bill To',

    account_type_field: 'Account Type',
    cost_center_field: 'Cost Center',
    cost_center_capex_warning: 'You cannot select Cost Center for CAPEX PR Line Item',
    internal_order_field: 'Internal Order',
    internal_order_placeholder: 'Select one from the list if it’s an internal order...',
    gl_account_field: 'GL Account',
    is_this_supplier_defines_as_a_subcontractor: 'Is this Supplier defines as a Subcontractor?',
    tax_code: 'Tax Code',
    select_one_from_the_list: 'Select one from the list...',
    cost_center_or_internal_order_message:
      'Please select for at least one of the fields Cost Center or Internal Order.',
    internal_order_message: 'Please select for Internal Order.',

    additional_comments_placeholder: 'Enter any additional comments',
    share_my_comment_with_supplier: 'Share my comment with Supplier',

    save_button: 'Save Line item',

    change_purchase_type_header: 'Change purchase type?',
    change_purchase_type_text:
      'You’re modifying the purchase type. This will discard any changes you’ve made to the current Line Item. Do you still want to proceed with the purchase type change?',
    change_purchase_type_confirm_button: 'YES, CHANGE IT',
    change_purchase_type_cancel_button: 'GO BACK AND EDIT',

    is_required: 'is required',

    characters_of_max: 'characters',

    line_items_none_header: 'No line items added',
    line_items_none_text: 'Complete the form to add a line item to your purchase request.',
    line_item_add: 'Add new line item',
    line_item_remove: 'REMOVE',
    line_item_header: 'LINE ITEM',

    discard_the_line_item: 'Discard the line item?',
    if_you_leave_the_form_now_your_changes_will_be_lost:
      'If you leave the form now, your changes will be lost. Do you want to discard this line item?',
    discard: 'DISCARD',
    go_back_and_change: 'GO BACK AND CHANGE',

    delete_line_item_header: 'Delete line item?',
    delete_line_item_text:
      'Are you sure you want to delete this line item? This action cannot be undone.',
    delete_line_cancel_button: 'CANCEL',
    delete_line_delete_button: 'DELETE',

    saved_line_item_header: 'Saved line item!',
    saved_line_item_text: 'You successfully saved the line item.',
    saved_line_item_button: 'OKAY',

    // step 3 - attachments

    max: 'max.',
    or: 'or',
    were_unable_to_upload_your_file: 'We’re unable to upload your file!',
    we_faced_some_issues_with_your_file: 'We faced some issues with your file',
    delete_attachment: 'Delete attachment?',
    are_you_sure_you_want_to_delete_this_attachment:
      'Are you sure you want to delete this attachment? You will remove the file completely from the Manual PR.',
    file_size_is_too_big: 'File size is too big: maximum file size is',
    file_number_is_to_big: 'The number of files is too big! You can only upload {NUM} files',
    file_type_is_not_supported: 'File type is not supported! You can only upload these files',
    delete: 'DELETE',
    cancel: 'CANCEL',
    share_with_supplier_checkbox: 'Share it with Supplier',
    share_with_supplier_label: 'Share attachment with Supplier?',
    share_with_supplier_yes: 'Yes',
    share_with_supplier_no: 'No',

    // step 4 - review and submit

    japan_subcontractor_no: 'No',
    japan_subcontractor_yes: 'Yes',
    japan_subcontractor_undefined: 'Undefined',
    additional_comment: 'Additional Comments',
    share_comment_with_supplier: 'Share comment with Supplier?',

    waiting_popup_header: 'Just a moment...',
    waiting_popup_text:
      'We’re sending your PR form to Ariba, please don’t close or reload the page, this might take several seconds.',

    success_popup_header: 'Success!',
    success_popup_text:
      'You’ve successfully created a purchase requisition. It may take a few minutes for the new PR to appear in your PR status list.',
    success_button_close_caption: 'OKAY',

    failure_popup_header: 'Something went wrong',
    failure_popup_text:
      'We were not able to create a purchase requisition with the details you provided. Please copy the error details below and raise a ticket so we will be able to help you.',
    failure_popup_report_button: 'Report the problem',
    failure_popup_close_button: 'Close',
  },
} satisfies TranslationsSchema;
